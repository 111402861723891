import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import SEO from 'components/SEO/SEO'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Preloader from 'components/Preloader/Preloader'
import Avatar from 'components/Avatar/Avatar'

import PageTopbar from 'layouts/Dashboard/components/PageTopbar/PageTopbar'

import { GET_ORGANIZATION } from 'api/organizations'

import OrganizationsTable from './components/OrganizationsTable/OrganizationsTable'
import UsersTable from './components/UsersTable/UsersTable'

import { getOrganizationName, getOrganizationAvatar } from './utils'

const Organization = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null)
    const { id } = useParams()

    useEffect(() => {
        GET_ORGANIZATION(id)
            .then(response => setData(response))
            .catch(err => {
                console.error(err)
                Toast(TOAST_TYPE.ERROR)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    if (isLoading) return <Preloader />

    return (
        <>
            <SEO title={`Organization "${data.name}"`} />
            <PageTopbar title={`Organization "${data.name}"`}>
                <Avatar
                    initials={getOrganizationName(data)}
                    url={getOrganizationAvatar(data)}
                    style={{
                        fontSize: 22,
                        width: 64,
                        height: 64,
                        boxShadow: 'rgba(0,0,0,.12) 0 2px 10px, rgba(0,0,0,.16) 0 2px 5px',
                    }}
                />
            </PageTopbar>

            <OrganizationsTable list={[data]} />
            <br />
            <br />
            <br />
            <UsersTable title="Owner" list={[data.owner]} userIdKey="id" tableIdLabel="User ID" />
            <br />
            <br />
            <UsersTable title="Members" list={data.members} userIdKey="id" tableIdLabel="Member ID" />
        </>
    )
}

export default Organization
