import axios from 'axios'

const SsrHttpClient = axios.create({
    baseURL: `${process.env.REACT_APP_SSR_URL}/api-proxy`,
    timeout: 10000,
})

SsrHttpClient.interceptors.request.use(
    config => {
        return config
    },
    async err => {
        return Promise.reject(err)
    },
)
SsrHttpClient.interceptors.response.use(
    async res => {
        return res.data
    },
    async err => {
        return Promise.reject(err)
    },
)

export default SsrHttpClient
