import React from 'react'

import SEO from 'components/SEO/SEO'
import ChatGPT from 'components/ChatGPT/ChatGPT'

import PageTopbar from 'layouts/Dashboard/components/PageTopbar/PageTopbar'

class GPTPage extends React.Component {
    render() {
        return (
            <>
                <SEO title="GPT" />
                <PageTopbar title="GPT" />
                <ChatGPT />
            </>
        )
    }
}

export default GPTPage
