import React from 'react'
import classNames from 'classnames'

import Tongue from './components/Tongue/Tongue'

import styles from './SlidingPanel.module.scss'

const SlidingPanel = ({ margin, isVisible, onChangeVisible, icon, children }) => (
    <div className={classNames(styles.slidingPanel, { [styles.isVisibleSlidingPanel]: isVisible })}>
        <Tongue
            margin={margin}
            title={isVisible ? 'Hide' : 'Show'}
            isActive={isVisible}
            icon={icon}
            onSetActive={v => onChangeVisible(v)}
        />
        <div className={styles.content}>{children}</div>
    </div>
)

export default SlidingPanel
