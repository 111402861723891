import axios from 'axios'
import { LOGOUT } from '../auth'

const HttpClient = axios.create({
    headers: {
        'Accept-Language': 'en',
    },
    baseURL: `/api`,
    timeout: 600000,
    withCredentials: true,
})

HttpClient.interceptors.request.use(
    config => {
        return config
    },
    async err => {
        return Promise.reject(err)
    },
)
HttpClient.interceptors.response.use(
    async res => {
        return res.data
    },
    async err => {
        if (err.response) {
            if (isUnauthorizedError(err.response)) await LOGOUT()
            // if (isNotFoundError(err.response)) window.location.href = '/404'
        }
        return Promise.reject(err)
    },
)

const isUnauthorizedError = ({ status }) => status === 401 || status === 403

export default HttpClient
