import React from 'react'

import iconClose from './i/close.png'

import styles from './Tongue.module.scss'

const Tongue = ({ margin = 0, title, isActive, icon, onSetActive }) => (
    <div
        title={title}
        style={{ backgroundImage: `url(${isActive ? iconClose : icon})`, marginLeft: `${margin}px` }}
        className={styles.tongue}
        onClick={() => onSetActive(!isActive)}
    />
)

export default Tongue
