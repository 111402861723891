import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { getAllowedNavList } from 'constants/navList'

import './Sidebar.scss'

const Sidebar = () => {
    const [navList] = useState(() => getAllowedNavList())

    return (
        <aside>
            <nav className="sidebar-nav">
                <ul className="sidebar-nav__list">
                    {navList.map((item, i) => (
                        <li key={i}>
                            <NavLink exact {...item.additionalProps} to={item.to}>
                                {item.icon && <img src={item.icon} alt={item.label} />}
                                {item.label}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </aside>
    )
}

export default Sidebar
