import React, { useState } from 'react'
import classNames from 'classnames'

import Select from 'components/Forms/Select/Select'
import TextArea from 'components/Forms/TextArea/TextArea'
import FormBlock from 'components/Forms/FormBlock'
import Preloader from 'components/Preloader/Preloader'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'

import { GET_CHAT_COMPLETIONS } from 'api/projectGenerator'

import gptIcon from 'images/icons/gpt.svg'

import Send from './svg/Send'

import { MODELS } from './constants'

import styles from './ChatGPT.module.scss'

const ChatGPT = ({ isCompact = false }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [model, setModel] = useState({ value: MODELS.GPT_4, label: MODELS.GPT_4 })
    const [prompt, setPrompt] = useState('')
    const [result, setResult] = useState('')

    const onSend = async () => {
        try {
            setIsLoading(true)
            const response = await GET_CHAT_COMPLETIONS(prompt, { model: model.value })
            setResult(response)
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={classNames(styles.chatGPT, { [styles.isCompactChatGPT]: isCompact })}>
            <div className={styles.setup}>
                {isCompact && <h4 className={styles.title}>GPT</h4>}
                <div className={styles.settings}>
                    <FormBlock label="AI Model">
                        <Select
                            isPortal={false}
                            value={model}
                            options={Object.keys(MODELS).map(key => ({
                                value: key,
                                label: MODELS[key],
                            }))}
                            onChange={v => setModel(v)}
                            isDisabled={isLoading}
                        />
                    </FormBlock>
                </div>
                <div className={styles.playground}>
                    <FormBlock label="Prompt">
                        <div className={styles.promptBox}>
                            <TextArea
                                rows={4}
                                isDisabled={isLoading}
                                value={prompt}
                                onChange={value => setPrompt(value)}
                                isWide
                            />
                            <div
                                className={classNames(styles.sendButton, {
                                    [styles.isDisabledSendButton]: isLoading || !prompt,
                                })}
                                onClick={onSend}
                            >
                                <Send />
                            </div>
                        </div>
                    </FormBlock>
                </div>
            </div>
            <div className={styles.result}>
                {isLoading ? (
                    <Preloader className={styles.resultPreloader} />
                ) : (
                    <>{!!result ? result : <img src={gptIcon} alt="gpt" />}</>
                )}
            </div>
        </div>
    )
}

export default ChatGPT
