import axios from 'axios'

import HttpClient from './instance'
import SsrHttpClient from './instance/ssr'

export const CREATE_TRANSLATION = async ({ text, languageCode }) =>
    HttpClient({
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        url: '/admin/translations',
        data: {
            id: null,
            text,
            languageCode,
        },
    })

export const GET_TRANSLATION = async id =>
    HttpClient({
        method: 'get',
        url: `/admin/translations/${id}`,
    })

export const UPDATE_TRANSLATION = async (id, { text, languageCode }) =>
    HttpClient({
        method: 'put',
        url: `/admin/translations/${id}`,
        data: {
            id,
            text,
            languageCode,
        },
    })

export const REMOVE_TRANSLATION = async id =>
    HttpClient({
        method: 'delete',
        url: `/admin/translations/${id}`,
    })

export const REMOVE_LOCALE_FROM_TRANSLATION = async (id, languageCode) =>
    HttpClient({
        method: 'delete',
        url: `/admin/translations/${id}/code/${languageCode}`,
    })

export const GET_DEEPL_USAGE_INFO = () =>
    SsrHttpClient({
        url: `/translator/usage`,
    })

export const GET_DEEPL_TRANSLATE = async ({ sourceLocale, targetLocale, sourceText }) =>
    SsrHttpClient({
        url: `/translator/translate`,
        params: {
            sourceLocale,
            targetLocale,
            text: sourceText,
        },
    })
